<template>
  <BlurredBackdrop
    :density="1"
    :is-blurred="isBlurred"
    :margin-top="marginTop"
    :min-height="minHeight"
  >
    <template v-slot:info>
      <div>
        {{ $t('alerts.upselling.upgrade.0') }}
        <router-link
          to="/settings/contract"
          class="pointer white--text font-weight-bold"
        >
          {{ $t('alerts.upselling.upgrade.link') }}
        </router-link>
        {{ $t('alerts.upselling.upgrade.1') }}
      </div>
    </template>
    <slot />
  </BlurredBackdrop>
</template>

<script>
import BlurredBackdrop from './BlurredBackdrop.vue'

export default {
  components: { BlurredBackdrop },
  props: {
    isBlurred: {
      type: Boolean,
      default: true
    },
    marginTop: {
      type: String,
      default: '0'
    },
    minHeight: {
      type: String,
      default: '150px'
    }
  }
}
</script>
